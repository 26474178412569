import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import { storage, getDocument } from "../../firebaseProvider";
import Carousel, { Modal, ModalGateway } from "react-images";
//import Carousel from "react-responsive-carousel";

import "./GalleryBall.css";
import { Spinner } from "../Spinner/Spinner";

const GallaryBall = ({ collection }) => {
  const [initDataLoading, setInitDataLoading] = useState(false);
  const [images2014, setImages2014] = useState([]);
  const [images2016, setImages2016] = useState([]);
  const [images2017, setImages2017] = useState([]);
  const [images2018, setImages2018] = useState([]);
  const [images2019, setImages2019] = useState([]);
  const [images2022, setImages2022] = useState([]);

  const [activeTab, setActiveTab] = useState("2022");

  const loadData = (year, setFunction) => {
    getDocument(collection, year).then((input) => {
      input &&
        input.images &&
        input.images.forEach((element) => {
          storage
            .child(collection)
            .child(year)
            .child(element)
            .getDownloadURL()
            .then((url) => {
              setFunction((prevState) => [
                ...prevState,
                {
                  src: url,
                  width:
                    element.substring(
                      element.length - 8,
                      element.length - 4
                    ) === "vert"
                      ? 0.8
                      : 1.15,
                  height:
                    element.substring(
                      element.length - 8,
                      element.length - 4
                    ) === "vert"
                      ? 1.15
                      : 0.8,
                },
              ]);
            });
        });
    });
  };

  if (!initDataLoading) {
    setInitDataLoading(true);
    loadData("2022", setImages2022);
  }

  const changeTab = (year, loadNewData, setFunction) => {
    setActiveTab(year);
    if (loadNewData) {
      loadData(year, setFunction);
    }
  };

  return (
    <div className="gallery">
      <div className="tab">
        <button
          className={`tab-link ${
            activeTab === "2022" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2022", !images2019 ? false : true, setImages2022)
          }
        >
          2022
        </button>
        <button
          className={`tab-link ${
            activeTab === "2019" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2019", !images2019 ? false : true, setImages2019)
          }
        >
          2019
        </button>
        <button
          className={`tab-link ${
            activeTab === "2018" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2018", !images2018 ? false : true, setImages2018)
          }
        >
          2018
        </button>
        <button
          className={`tab-link ${
            activeTab === "2017" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2017", !images2017 ? false : true, setImages2017)
          }
        >
          2017
        </button>
        <button
          className={`tab-link ${
            activeTab === "2016" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2016", !images2016 ? false : true, setImages2016)
          }
        >
          2016
        </button>
        <button
          className={`tab-link ${
            activeTab === "2014" ? "tab-link-active" : ""
          }`}
          onClick={() =>
            changeTab("2014", !images2014 ? false : true, setImages2014)
          }
        >
          2014
        </button>
      </div>
      <div className="gallery-show">
        {activeTab === "2022" && (
          <div className="tab-content" key="2022">
            {images2022 ? (
              <PhotoGaleryItem images={images2022} year="2022" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
        {activeTab === "2019" && (
          <div className="tab-content" key="2019">
            {images2019 ? (
              <PhotoGaleryItem images={images2019} year="2019" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
        {activeTab === "2018" && (
          <div className="tab-content" key="2018">
            {images2018 ? (
              <PhotoGaleryItem images={images2018} year="2018" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
        {activeTab === "2017" && (
          <div className="tab-content" key="2017">
            {images2018 ? (
              <PhotoGaleryItem images={images2017} year="2017" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
        {activeTab === "2016" && (
          <div className="tab-content" key="2016">
            {images2016 ? (
              <PhotoGaleryItem images={images2016} year="2016" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
        {activeTab === "2014" && (
          <div className="tab-content" key="2014">
            {images2014 ? (
              <PhotoGaleryItem images={images2014} year="2014" />
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GallaryBall;

const PhotoGaleryItem = ({ images, year }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div key={images[0] + year}>
      <Gallery photos={images} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox} allowFullscreen={false}>
            <Carousel
              allowFullscreen={false}
              height={100}
              currentIndex={currentImage}
              views={images.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>{" "}
    </div>
  );
};
